import React, { useState, useEffect } from 'react';
import './App.css';
import { FaSearch, FaPlus } from 'react-icons/fa';
import ClienteForm from './Componentes/ClienteForm';

function App() {
  const [query, setQuery] = useState('');
  const [resultados, setResultados] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const dados = [
    { cnpj: '12.345.678/0001-90', razaoSocial: 'Empresa A', status: 'Ativo', inadimplente: 'Não', vencimento: '15/06/2024' },
    { cnpj: '98.765.432/0001-12', razaoSocial: 'Empresa B', status: 'Inativo', inadimplente: 'Sim', vencimento: '20/05/2024' },
    { cnpj: '11.222.333/0001-44', razaoSocial: 'Empresa C', status: 'Ativo', inadimplente: 'Não', vencimento: '10/07/2024' },
    // Adicione mais dados conforme necessário
  ];

  useEffect(() => {
    setResultados(dados); // Exibe todos os registros inicialmente
  }, []);

  const buscar = (event) => {
    const query = event.target.value;
    setQuery(query);
    const resultadosFiltrados = dados.filter(item =>
      item.razaoSocial.toLowerCase().includes(query.toLowerCase())
    );
    setResultados(resultadosFiltrados);
  };

  const handleInsert = (newData) => {
    setResultados([...resultados, newData]);
  };

  return (
    <div className="container">
      {showForm ? (
        <ClienteForm
          onClose={() => setShowForm(false)}
          onSave={handleInsert}
        />
      ) : (
        <>
          <div className="search-container">
            <FaSearch className="search-icon" />
            <input
              type="text"
              value={query}
              onChange={buscar}
              className="search-input"
              placeholder="Digite sua busca..."
            />
          </div>
          <table>
            <thead>
              <tr>
                <th>CNPJ</th>
                <th>Razão Social</th>
                <th>Status</th>
                <th>Inadimplente</th>
                <th>Dia do Vencimento</th>
              </tr>
            </thead>
            <tbody>
              {resultados.map((item, index) => (
                <tr key={index}>
                  <td>{item.cnpj}</td>
                  <td>{item.razaoSocial}</td>
                  <td>{item.status}</td>
                  <td>{item.inadimplente}</td>
                  <td>{item.vencimento}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <button className="insert-button" onClick={() => setShowForm(true)}>
            <FaPlus />
          </button>
        </>
      )}
    </div>
  );
}

export default App;
