import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './index.css';

const ClienteForm = ({ onClose, onSave }) => {
  const [formData, setFormData] = useState({
    cnpj: '',
    razaoSocial: '',
    status: '',
    inadimplente: '',
    vencimento: '',
    email: '',
    telefone: '',
    cep: '',
    endereco: '',
    estado: '',
    cidade: '',
    bairro: '',
    complemento: '',
    uniqueId: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSave(formData);
    onClose();
  };

  return (
    <div className="form-container">
      <form className="cliente-form" onSubmit={handleSubmit}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Informações Obrigatórias</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              name="cnpj"
              value={formData.cnpj}
              onChange={handleInputChange}
              label="CNPJ"
              fullWidth
              margin="normal"
              required
            />
            <TextField
              name="razaoSocial"
              value={formData.razaoSocial}
              onChange={handleInputChange}
              label="Razão Social"
              fullWidth
              margin="normal"
              required
            />
            <TextField
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              label="Email"
              fullWidth
              margin="normal"
              required
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Adicional</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                required
              >
                <MenuItem value="ativo">Ativo</MenuItem>
                <MenuItem value="inativo">Inativo</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Inadimplente</InputLabel>
              <Select
                name="inadimplente"
                value={formData.inadimplente}
                onChange={handleInputChange}
                required
              >
                <MenuItem value="sim">Sim</MenuItem>
                <MenuItem value="nao">Não</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="vencimento"
              label="Dia do Vencimento"
              type="number"
              value={formData.vencimento}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
              inputProps={{ min: 1, max: 31 }}
            />
            <TextField
              name="telefone"
              value={formData.telefone}
              onChange={handleInputChange}
              label="Telefone"
              fullWidth
              margin="normal"
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Endereço</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              name="cep"
              value={formData.cep}
              onChange={handleInputChange}
              label="CEP"
              fullWidth
              margin="normal"
            />
            <TextField
              name="endereco"
              value={formData.endereco}
              onChange={handleInputChange}
              label="Endereço"
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Estado</InputLabel>
              <Select
                name="estado"
                value={formData.estado}
                onChange={handleInputChange}
              >
                <MenuItem value="MT">MT</MenuItem>
                {/* Adicione outros estados conforme necessário */}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Cidade</InputLabel>
              <Select
                name="cidade"
                value={formData.cidade}
                onChange={handleInputChange}
              >
                <MenuItem value="Cuiabá">Cuiabá</MenuItem>
                {/* Adicione outras cidades conforme necessário */}
              </Select>
            </FormControl>
            <TextField
              name="bairro"
              value={formData.bairro}
              onChange={handleInputChange}
              label="Bairro"
              fullWidth
              margin="normal"
            />
            <TextField
              name="complemento"
              value={formData.complemento}
              onChange={handleInputChange}
              label="Complemento"
              fullWidth
              margin="normal"
            />
          </AccordionDetails>
        </Accordion>
        <div className="form-actions">
          <Button type="submit" variant="contained" color="primary">Salvar</Button>
          <Button type="button" variant="contained" color="secondary" onClick={onClose}>Cancelar</Button>
        </div>
      </form>
    </div>
  );
};

export default ClienteForm;
